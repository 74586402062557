@import url(https://fonts.googleapis.com/css2?family=Wittgenstein:ital,wght@0,400..900;1,400..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Oswald:wght@200..700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Oswald:wght@200..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Gupter:wght@400;500;700&family=Oswald:wght@200..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);

:root{
  --color-primary: #E55439;
  --color-primary: #ed2e2e;
}

/* gral */
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
}
.m_color_primary{
  color: var(--color-primary);
}
.m_bg_primary {
  background: var(--color-primary) !important;
}
.m_font_primary {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}
.m_font_hover_color_primary:hover{
  color: var(--color-primary) !important;
}
.m_bg_amarillo{
  background: rgb(236, 236, 7);
}
.m_bg_gray {
  background: #50504f;
}
/*  rounded */
.m_rounded_top{
  border-radius: 10px 10px 0 0;
}
.m_rounded_bot{
  border-radius:0 0 10px 10px;
}
/* medidas */
.m_w_100vw{
  width: 100vw;
}
.m_w_10{
  width: 10%;
}
.m_w_20{
width: 20%;
}
.m_w_25{
  width: 25%;
  }
.m_w_30{
  width: 30%;
  }
.m_w_40{
  width: 40%;
}
.m_w_60{
  width: 60%;
}
.m_w_70{
  width: 70%;
}
.m_w_80{
  width: 80%;
}
.m_w_90{
  width: 90%;
}
.m_h_100vh {
  min-height: 100vh;
}
/* rounded */


/* decoracion */
.m_empityBox{
  width: 100%;
  height: 50px;
}
.m_line_white {
  width: 100%;
  height: 1px;
  background: #fff;
}
.m_line_black {
  width: 100%;
  height: 1px;
  background: #000;
}
.m_line_white_70 {
  width: 70%;
  height: 1px;
  background: #fff;
}

/* home */
.m_ctn_programacionActual {
  background: #464646a1;
}

.m_header_home {
  background-image: url(./asset/degradadoHome.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.parkSessionHome {
  position: relative; /* Hacemos que el contenedor sea relativo para posicionar el span en relación a él */
  text-align: center; /* Alineamos el contenido al centro horizontalmente */
}

.parkSessionHome img {
  width: 100%; /* La imagen ocupa el 100% del ancho del contenedor */
  height: auto; /* La altura se ajusta automáticamente para mantener la proporción */
}
.parkSessionHome div {
  position: absolute; /* Posicionamos el span absolutamente dentro del contenedor */
  top: 50%; /* Centramos verticalmente */
  left: 50%; /* Centramos horizontalmente */
  transform: translate(-50%, -50%); /* Ajustamos la posición para que el centro del span esté en el centro del contenedor */
  cursor: pointer; /* Cambia el cursor para que indique que es clickeable */
}
.m_pointer{
  cursor: pointer;
}
.m_ctn_destacado{
  border-radius: 0 0 15px 15px;
}
.m_ctn_destacado img {
  border-radius: 0 0 15px 15px;
}
.ms_banner_parkSession_hover:hover{
  box-shadow: 0px 0px 10px 3px var(--color-primary);
-webkit-box-shadow: 0px 0px 10px 3px var(--color-primary);
-moz-box-shadow: 0px 0px 10px 3px var(--color-primary);
}
.m_btn_playStop{
  background: #00000070;
}
.m_btn_playStop:hover{
  background: #0000009d;
}
.btn-ver-mas{
  border: none;
  background: #000;
}
.btn-ver-mas:hover {
 background: rgb(0, 0, 0);
} 
/* Footer */
.m_slogan_footer {
  background: rgb(2,0,36);
  background: linear-gradient(75deg, rgba(2,0,36,1) 0%, rgba(186,61,19,1) 15%, rgba(186,61,19,1) 24%, rgba(0,0,0,1) 53%);
}
.m_btn_footer {
  border-radius: 20%;
  padding: 15px 10px;
}

/* AudioSpectrum.css */
@keyframes barAnimation {
  0% { transform: scaleY(0.1); }
  50% { transform: scaleY(0.2); }
  100% { transform: scaleY(0.1); }
}

@keyframes moveLeft {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.audio-spectrum {
  display: flex;
  align-items: flex-end;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.bar-container {
  display: flex;
  align-items: center;
  position: absolute;
  width: calc(100% * 2); /* Doble el ancho del contenedor para el efecto de desplazamiento */
  height: 100%;
}

.bar {
  width: 5px;
  margin: 0 2px;
  background: #f1f1f1;
  animation: barAnimation 2.0s infinite ease-in-out;
}

.bar:nth-child(even) {
  animation-delay: 2.0s;
}

.bar:nth-child(odd) {
  animation-delay: 2.2s;
}

/* notas leer */
.m_share_buttons {
  position: fixed; /* Fija el contenedor a la ventana de visualización */
  top: 50%; /* Centra verticalmente */
  left: 10px; /* Espacio desde el borde izquierdo */
  transform: translateY(-50%); /* Ajusta la posición para que esté centrado verticalmente */
  z-index: 1000; /* Asegúrate de que los botones estén por encima de otros elementos */
}
/* notas */
.note-card {
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.note-img-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Relación de aspecto 16:9 */
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.m_card_note h2{
  color: rgb(207, 207, 207);
  font-size: 1rem;
}
.note-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card .span1 {
  width: 43%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 0 0 20px;
}
.card .span2 {
  width: 43%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0 20px 0 0;
}
.user-img-container {
  position: absolute;
  bottom: -20px;
  left: 10px;
  width: 50px;
  height: 50px;
}
.m_img_user{
  position: absolute;
  width: 80px !important;
  height: 80px !important;
  bottom: 10px;
  right: 10px;
  transform: translate(50%, 50%);
  border-radius: 50%;
}
.m_img_userMobile {
  position: absolute;
  width: 80px !important;
  height: 80px !important;
  bottom: 0;
  right: 0;
   transform: translate(2%, 50%); 
  border-radius: 50%;
}
.m_notas_page {
  min-height: 100vh;
}
.m_notas_page h4{
  font-size: 6rem;
}
.m_notas_page p {
  color: #d1d1d1;
}
.m_ctn_title_autor {
  min-height: 10em;
}
/* nav */
.m_menu_burger{
  position: fixed;
  top: 35px;
  right: 35px;
  z-index: 999999999999;
}

.m_menu_desplegable {
  position: fixed;
  top: 0;
  right: 0px;
  width: 40%;
  height: 100%;
  z-index: 999999;
  background-image: url(./asset/texturaNav.png);
  transition: all 0.3s ease-in-out;
}

.m_menu_desplegable button, .m_menu_desplegableMobile button {
  background: #CB3327;
  color: #f7f7f7;
}
/* nav Mobile*/ 
.m_menu_desplegableMobile {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  z-index: 999999;
  background-image: url(./asset/texturaNav.png);
  transition: all 0.3s ease-in-out;
} 
.right {
  right: -45%;
  transition: all 0.3s ease-in-out;
}
.m_right_mobile_nav {
  right: -100%;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1080px) {
  .m_menu_desplegable {
    width: 55%;
  }
  .right {
    right: -55%;
  }
}
@media (max-width: 670px) {
  .m_menu_desplegable {
    width: 100%;
  }
  .right {
    right: -100%;
  }
}
/* parkSession*/

.m_header_notas {
  background-image: url(./asset/notasBG.png);
  background-size: cover;
}
.m_header_ps_mobile{
  background-image: url(./asset/parkSessionBg.png);
  background-size: cover;
}
.m_header_notas_mobile {
  background-image: url(./asset/notasBgMobile.png);
  background-size: cover;
}
.m_header_ps .subHeaderPS, .m_header_ps_mobile > div:first-child, .m_header_notas > div:first-child, .m_header_notas_mobile > div:first-child{
  background: #00000067 !important;
}
.m_header_ps h2, .m_header_notas h2{
  font-size: 11rem;
  transform: scaleY(1.7);
}

.m_header_ps_mobile h2, .m_header_notas_mobile h2{
  font-size: 5rem;
  transform: scaleY(1.7);
}
.m_bg_stars {
  background-image: url(./asset/bg/parallaxEstrella.jpg);
 
  background-attachment: fixed;
}
.m_bg_stars .m_bg_dark{
  background: #00000062;
}
.m_moon_left {
  background-image: url(./asset/bg/parallaxLunaIzquierda2.png);
  background-repeat: no-repeat;
  background-size: 35%;
  background-position: top 30% right 40%;
  background-attachment: fixed;

}
.bg-lineup {
  background-image: url(./asset/bg551.png);
  background-size: 100%;
  background-repeat: repeat-y;
}
.affdggs {
  transition: 2s ease-in-out;
}
.col {
  transition: all 0.5s ease-in-out !important; /* Transición suave */
}