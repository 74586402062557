*{
  padding: 0;
  margin: 0;
}
h1 {
  font-size: 7rem !important;
  transform: scaleY(1.4);
}
html {
  scroll-behavior: auto !important;
}
/* en vivo */
.m_live_button_fixed {
  width: 140px;
  height: 40px;
}
.m_live_button_activo_fixed {
  width: 140px;
  height: 40px;
  box-shadow: 0px 0px 45px -3px #e55339bd;
  -webkit-box-shadow: 0px 0px 45px -3px #e55339;
  -moz-box-shadow: 0px 0px 45px -3px #e55339ce;
}
.m_live_button {
  width: 200px;
  height: 50px;
}
.m_live_button_activo {
  width: 200px;
  height: 50px;
  box-shadow: 0px 0px 45px -3px #e55339bd;
  -webkit-box-shadow: 0px 0px 45px -3px #e55339;
  -moz-box-shadow: 0px 0px 45px -3px #e55339ce;
}

.m_live_button_circle {
  border-radius: 15px 0 0 15px;
}

.m_circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.m_live_button_enVivo{
  border-radius: 0 15px 15px 0;
}
.m_radio-player { /* Asegúrate de que la clase coincida */
  position: fixed;
  top: 0 !important;
  z-index: 999999;
}


@media (max-width: 600px) {
  .m_radio-player { /* Asegúrate de que la clase coincida */
    position: fixed;
    top: auto !important; /* Asegúrate de que top no tenga valor */
    bottom: 15px !important;
    left: 10px;
    z-index: 999999;
  }
}

@keyframes scrollLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.m_nav_programacion {
  position: fixed;
  top: 0px;
  z-index: 1000;
  min-width: 100%;
  height: 50px;
  background: #00000094;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.m_nav_programacion p {
  display: inline-block;
  padding: 0 20px;
}

.m_nav_programacion span {
  display: inline-block;
  padding: 0 10px;
}

.m_nav_programacion {
  animation: scrollLeft 70s linear infinite;
}
.ñññ {
  transform: scaleY(1.1);
}
/* notas */ 
.ñrow {
  display: flex;
  flex-wrap: wrap;
}

.ñcard {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 110%;
}

.ñimg-container {
  flex: 1 0 auto;
}

.ñm_card-body_note {
  flex: 1 1 auto;
}
.ñimg-container {
  width: 100%;
  height: 200px; /* Ajusta esta altura según tus necesidades */
  overflow: hidden;
}

.ñimg-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.m_line_Nota{
  width: 100%;
  height: 1px;
  background: var(--color-primary);
}
.ñm_color_gray_notas {
  color: #777;
}
.ñno-border {
  border: none !important;
}
.ñbg-dark {
  background: #000000 !important;
}
.btn-ver-mas{
  background-color: rgba(0, 0, 0, 0.539);
}
/* botones generales  */
.me_button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  color: #0f1923;
  cursor: pointer;
  position: relative;
  padding: 8px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  transition: all .15s ease;
}

.me_button::before,
.me_button::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: calc(50% - 5px);
  border: 1px solid #7D8082;
  transition: all .15s ease;
}

.me_button::before {
  top: 0;
  border-bottom-width: 0;
}

.me_button::after {
  bottom: 0;
  border-top-width: 0;
}

.me_button:active,
.me_button:focus {
  outline: none;
}

.me_button:active::before,
.me_button:active::after {
  right: 3px;
  left: 3px;
}

.me_button:active::before {
  top: 3px;
}

.me_button:active::after {
  bottom: 3px;
}

.me_button_lg {
  position: relative;
  display: block;
  padding: 10px 20px;
  color: #fff;
  background-color: #0f1923;
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 1px transparent;
}

.me_button_lg::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  background-color: #0f1923;
}

.me_button_lg::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4px;
  height: 4px;
  background-color: #0f1923;
  transition: all .2s ease;
}

.me_button_sl {
  display: block;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: -8px;
  width: 0;
  background-color: #ff4655;
  transform: skew(-15deg);
  transition: all .2s ease;
}

.me_button_text {
  position: relative;
}
.me_button_text a {
  color: #f1f1f1;
}

.me_button:hover {
  color: #0f1923;
}

.me_button:hover .me_button_sl {
  width: calc(100% + 15px);
}

.me_button:hover .me_button_lg::after {
  background-color: #fff;
}


/*parallax*/
.m_ps_stars {
  background-image: url(./asset/bg/parallaxEstrella.jpg);
  background-attachment: fixed;
  background-size: cover;
}
.header_ParkSession {
  background-image: url(./asset/bg/parallaxLunaInferior.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  
}
.m_luna_derechaTop {
  background-image: url(./asset/bg/lunaDerechaTop.png);
  background-size: 18%;
  background-position: top 0% right 0%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.m_ps_LunaIzquierda {
  background-image: url(./asset/bg/parallaxLunaIzquierda2.png);
  background-size: 21%;
  background-position: top 10% left 0%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.m_ps_astronauta{
  background-image: url(./asset/bg/parallaxAstronauta.png);
  background-repeat: no-repeat;
  background-position: top 50% left 50%;
  background-size: 72rem;
  animation: austronautaTB 3s ease-in-out alternate-reverse infinite;
}
.m_ps_astronauta div {
  background: #0000008b;
}
.header_ParkSession h2 {
  font-size: 10rem;
  transform: scaleY(1.8);
}
@media screen and (max-width: 965px) {
  .header_ParkSession h2 {
    font-size: 7rem;
    transform: scaleY(1.8);
  }
}
@keyframes austronautaTB {
  0% {
    background-position: top 20% left 50% ;
  }

  100% {
    background-position: top 80% left 50% ;
  }
  
}
@keyframes ps_luna {
  0% {
    background-position: top 20% left 0% ;
  }
  100% {
    background-position: top 20% left 120% ;
  }
  
}